<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Archived {{ pageTitle }} Categories</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Archived Categories"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-btn depressed light :to="{ name: parentRouteName }">
              <v-icon small left>mdi-arrow-left</v-icon> Back to Categories
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="tableHeaders"
            :items="articles"
            no-data-text="No Archived Articles found"
          >
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    pageTitle: String,
    parentRouteName: {
      type: String,
      required: true,
    },
    parentCategoryName: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "groGreen",
          disabled: false,
          to: { name: "app-dashboard", params: { id: this.$route.params.id } },
        },
        {
          text: "Articles",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Title", value: "title" },
        { text: "Slug", value: "slug" },
      ],
    };
  },

  mounted() {
    this.breadcrumbs.push(
      {
        text: this.pageTitle,
        disabled: false,
        exact: true,
        to: {
          name: this.parentRouteName,
        },
      },
      {
        text: "Categories",
        disabled: false,
        exact: true,
        to: {
          name: this.parentCategoryName,
        },
      },
      {
        text: "Archived",
        disabled: true,
      }
    );
  },

  computed: {
    articles() {
      let categories =
        this.$store.state.grogreen.articles["archivedCategories"];

      if (this.searchTerm !== "") {
        categories = categories.filter((c) => {
          const title = c.title.toLowerCase();
          const slug = c.slug.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();
          return title.includes(searchTerm) || slug.includes(searchTerm);
        });
      }

      return categories;
    },
  },
};
</script>
